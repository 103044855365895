import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"red darken-2","outlined":""}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-alert")]),_vm._v(" Supprimer cette cantine ")],1)]}}]),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c(VCard,{staticClass:"text-left"},[_c(VCardTitle,[_c('h1',{staticClass:"fr-h5 mb-2"},[_vm._v("Voulez-vous vraiment supprimer « "+_vm._s(_vm.canteen.name)+" » ?")])]),_c(VCardText,[_c('p',{staticClass:"mb-0"},[_vm._v(" Vous perdrez les données de cette cantine ainsi que celles des diagnostics associés. ")])]),_c(VDivider,{attrs:{"aria-hidden":"true","role":"presentation"}}),_c(VCardActions,{staticClass:"pa-4"},[_c(VSpacer),_c(VBtn,{staticClass:"mr-2",attrs:{"outlined":"","text":""},on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v(" Non, revenir en arrière ")]),_c(VBtn,{attrs:{"outlined":"","color":"red darken-2","text":""},on:{"click":function($event){return _vm.$emit('delete')}}},[_vm._v(" Oui, supprimer ma cantine ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }