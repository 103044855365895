import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{attrs:{"items":_vm.actionsDisplay,"headers":_vm.listHeaders,"hide-default-footer":true,"disable-sort":true},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'WasteActionPage', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.effort",fn:function(ref){
var item = ref.item;
return [_c('DsfrTag',{attrs:{"text":item.effort.text,"icon":item.effort.icon,"small":true,"clickable":false}})]}},{key:"item.wasteOrigins",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-wrap justify-end justify-sm-start my-2"},_vm._l((item.wasteOrigins),function(tag){return _c('DsfrTag',{key:tag.text,attrs:{"text":tag.text,"icon":tag.icon,"small":true,"clickable":false}})}),1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }