<template>
  <v-card outlined class="d-flex align-center">
    <div>
      <v-card-text class="body-2">
        <p class="mb-0">
          <v-icon small color="green" class="mt-n1">mdi-check-circle</v-icon>
          Vous n'avez pas d'actions en attente pour vos établissements
        </p>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "SuccessBanner",
}
</script>
