import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VListItem,[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":_vm.isInvitation ? 'secondary' : 'primary'}},[_vm._v(" "+_vm._s(_vm.isInvitation ? "mdi-account-clock" : "mdi-account-check")+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(_vm.isInvitation ? _vm.manager.email : ((_vm.manager.firstName) + " " + (_vm.manager.lastName)))}}),_c(VListItemSubtitle,[(_vm.isInvitation)?_c('span',[_vm._v("Invitation envoyée")]):(_vm.$store.state.loggedUser.email === _vm.manager.email)?_c('span',[_vm._v(" Vous êtes gestionnaire de cette cantine ")]):_c('span',[_vm._v(_vm._s(_vm.manager.email))])])],1),(_vm.showDeleteButton)?_c('AdminRemovalDialog',{attrs:{"manager":_vm.manager},on:{"delete":_vm.deleteManager},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}):_vm._e()],1),_c(VDivider,{staticClass:"my-1",attrs:{"aria-hidden":"true","role":"presentation"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }