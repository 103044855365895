import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"dsfr d-flex flex-column text-left fill-height",attrs:{"to":{
    name: 'PartnerPage',
    params: { partnerUrlComponent: _vm.$store.getters.getPartnerUrlComponent(_vm.partner) },
  },"outlined":"","ripple":false}},[(_vm.partner.image)?_c(VImg,{attrs:{"contain":"","src":_vm.partner.image,"height":"120","max-height":"120"}}):_c(VImg,{attrs:{"src":"/static/images/partner-default-image.jpg","height":"120","max-height":"120"}}),_c(VCardTitle,{staticClass:"font-weight-bold"},[_c('h3',{staticClass:"fr-h5 mb-2"},[_vm._v(_vm._s(_vm.partner.name))])]),_c(VCardSubtitle,[_c('PartnerIndicators',{attrs:{"partner":_vm.partner}})],1),_c(VCardText,[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.partner.shortDescription))])]),_c(VSpacer),_c(VCardActions,{staticClass:"px-4 py-4"},[_c(VSpacer),_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("$arrow-right-line")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }