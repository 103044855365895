import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,_vm._b({attrs:{"options":_vm.options,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.processedVisiblePurchases,"show-select":_vm.selectable},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'PurchasePage', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.description)+" "),_c('span',{staticClass:"d-sr-only"},[_vm._v(", "+_vm._s(item.date))])])]}},{key:"item.family",fn:function(ref){
var item = ref.item;
return [_c(VChip,{staticClass:"font-weight-bold",attrs:{"outlined":"","small":"","color":_vm.getProductFamilyDisplayValue(item.family).color,"dark":""}},[_vm._v(" "+_vm._s(_vm.capitalise(_vm.getProductFamilyDisplayValue(item.family).shortText))+" ")])]}},{key:"item.characteristics",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getProductCharacteristicsDisplayValue(item.characteristics))+" ")]}},{key:"item.priceHt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.priceHt.toLocaleString("fr-FR", { style: "currency", currency: "EUR" }))+" ")]}},{key:"item.hasAttachment",fn:function(ref){
var item = ref.item;
return [(item.hasAttachment)?_c(VIcon,{attrs:{"small":"","color":"grey","aria-label":"Facture attachée","aria-hidden":false}},[_vm._v(" mdi-paperclip ")]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"mx-10 my-10"},[_vm._v(" Cliquer sur le bouton \"Ajouter une ligne\" pour rentrer vos achats. ")])]},proxy:true}],null,true)},'v-data-table',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }