import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-left"},[(_vm.canteen)?_c('div',{attrs:{"id":"canteen-dashboard"}},[_c('BreadcrumbsNav',{attrs:{"links":[{ to: { name: 'CanteenSearchLanding' } }, { to: { name: 'CanteensHome' } }],"title":_vm.canteen.name}}),_c('ImageGallery',{attrs:{"images":_vm.canteen.images.slice(0, _vm.imageLimit)}}),_c(VCard,{staticClass:"pa-0 mb-8 text-left",attrs:{"elevation":"0"}},[_c(VRow,{staticClass:"align-center"},[(_vm.canteen.logo)?_c(VCol,{staticClass:"mr-4 d-none d-sm-block",staticStyle:{"border-right":"solid 1px #dfdfdf"},attrs:{"cols":"4","sm":"3","md":"2"}},[_c(VImg,{staticClass:"rounded",attrs:{"src":_vm.canteen.logo,"contain":""}})],1):_vm._e(),_c(VCol,[_c(VCardTitle,{staticClass:"text-h4 font-weight-black pa-0"},[_c('h1',{staticClass:"text-h4 font-weight-black"},[_vm._v(" "+_vm._s(_vm.canteen.name)+" ")])]),_c(VSpacer),_c(VCardSubtitle,{staticClass:"pa-0 pt-4 d-flex"},[(_vm.canteen.logo)?_c(VImg,{staticClass:"mr-4 rounded d-sm-none",attrs:{"max-width":"100px","max-height":"80px","src":_vm.canteen.logo,"contain":""}}):_vm._e(),_c('div',[_c('CanteenIndicators',{staticClass:"grey--text text--darken-3",attrs:{"useCategories":true,"canteen":_vm.canteen}}),(!_vm.canteen.canBeClaimed)?_c('router-link',{attrs:{"to":"#contact"}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-email-outline")]),_vm._v(" Contactez-nous ")],1):_vm._e()],1)],1)],1)],1)],1),(_vm.showClaimCanteen)?_c('div',[(_vm.undoSucceeded)?_c(VAlert,{attrs:{"colored-border":"","color":"primary","elevation":"2","border":"left","type":"success"}},[_vm._v(" Vous n'êtes plus gestionnaire de cet établissement. ")]):(_vm.claimSucceeded)?_c(VAlert,{attrs:{"colored-border":"","color":"primary","elevation":"2","border":"left","type":"success"}},[_c('p',[_vm._v(" Vous êtes maintenant gestionnaire. "),_c('router-link',{attrs:{"to":{
              name: 'DashboardManager',
              params: {
                canteenUrlComponent: _vm.$store.getters.getCanteenUrlComponent(_vm.canteen),
              },
            }}},[_vm._v(" Évaluez cet établissement ")])],1),_c('p',{staticClass:"mb-0"},[_vm._v(" S'agit-il d'une erreur ? "),_c(VBtn,{staticClass:"ml-2",attrs:{"outlined":"","color":"primary"},on:{"click":_vm.undoClaim}},[_vm._v(" Je ne suis pas gestionnaire de cet établissement ")])],1)]):_c('DsfrCallout',[_c('div',[_vm._v("Cet établissement n'a pas de gestionnaire associé. C'est votre établissement ?")]),(_vm.loggedUser)?_c('div',{staticClass:"mt-2"},[_c(VBtn,{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.claimCanteen}},[_vm._v("Rejoindre cet établissement")])],1):_c('div',[_c('a',{attrs:{"href":("/creer-mon-compte?next=" + _vm.currentPage)}},[_vm._v("Créez votre compte")]),_vm._v(" ou "),_c('a',{attrs:{"href":("/s-identifier?next=" + _vm.currentPage)}},[_vm._v("connectez-vous")]),_vm._v(" pour le rejoindre. ")])])],1):_vm._e(),_c('CanteenPublication',{attrs:{"canteen":_vm.canteen}}),_c('ContactForm',{staticClass:"mt-16",attrs:{"id":"contact","canteen":_vm.canteen}})],1):_c(VProgressCircular,{staticStyle:{"position":"absolute","left":"50%","top":"50%"},attrs:{"indeterminate":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }