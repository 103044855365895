import { render, staticRenderFns } from "./TeamWidget.vue?vue&type=template&id=66f2772c&scoped=true&"
import script from "./TeamWidget.vue?vue&type=script&lang=js&"
export * from "./TeamWidget.vue?vue&type=script&lang=js&"
import style0 from "./TeamWidget.vue?vue&type=style&index=0&id=66f2772c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66f2772c",
  null
  
)

export default component.exports