import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,[_c(VSpacer),_c(VCol,{attrs:{"cols":"12","sm":"8"}},[_c('h2',{staticClass:"text-h4 font-weight-black"},[_vm._v("Les mesures phares de la loi EGAlim en restauration collective")])]),_c(VSpacer)],1),_c('p',{staticClass:"text-left mt-8"},[_vm._v(" Découvrez les mesures, leurs échéances, le public cible ainsi que des ressources pour vous aider à atteindre leurs objectifs. ")]),_c(VRow,{staticClass:"justify-center mt-8 mx-0 mx-md-n4 cta-group pa-4"},_vm._l((_vm.keyMeasures),function(measure){return _c(VCol,{key:measure.id,attrs:{"cols":"12","sm":"4"}},[_c(VCard,{staticClass:"fill-height d-flex flex-column px-3 dsfr",attrs:{"outlined":"","to":{ name: 'KeyMeasurePage', params: { id: measure.id } }}},[_c(VCardText,{staticClass:"pb-1 pt-6"},[_c(VIcon,[_vm._v(_vm._s(measure.mdiIcon))])],1),_c(VCardTitle,[_c('h3',{staticClass:"text-body-1 text-left font-weight-bold"},[_vm._v(" "+_vm._s(measure.title)+" ")])]),_c(VSpacer),_c(VCardActions,{staticClass:"px-4 py-4"},[_c(VSpacer),_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("$arrow-right-line")])],1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }