import { render, staticRenderFns } from "./ApproSegment.vue?vue&type=template&id=106d87f4&scoped=true&"
import script from "./ApproSegment.vue?vue&type=script&lang=js&"
export * from "./ApproSegment.vue?vue&type=script&lang=js&"
import style0 from "./ApproSegment.vue?vue&type=style&index=0&id=106d87f4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "106d87f4",
  null
  
)

export default component.exports