import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"fill-height d-flex flex-column dsfr no-hover pa-sm-6",attrs:{"outlined":""}},[_c(VCardTitle,[_c('h3',{staticClass:"fr-h4 mb-2"},[_vm._v("Mon équipe")])]),_c(VCardText,{staticClass:"fill-height"},[(_vm.managers.length > 1)?_c('div',{staticClass:"fill-height d-flex flex-column"},[_c('p',{staticClass:"fr-text mb-0 grey--text text--darken-3"},[_vm._v(" "+_vm._s(_vm.managers.length)+" personnes (dont vous) peuvent actuellement modifier cet établissement et ajouter des données. ")]),_c(VSpacer),_c('ul',{staticClass:"pl-0 fr-text-xs grey--text text--darken-2 mb-n2"},_vm._l((_vm.managers),function(manager){return _c('li',{key:manager.email,staticClass:"mb-4"},[_c(VRow,{staticClass:"align-center mx-0"},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" "+_vm._s(manager.isInvite ? "$user-add-line" : "$user-line")+" ")]),_vm._v(" "+_vm._s(manager.isInvite ? manager.email : ((manager.firstName) + " " + (manager.lastName)))+" "),(manager.email === _vm.loggedUser.email)?_c('span',{staticClass:"ml-1"},[_vm._v("(vous)")]):_vm._e(),_c('span',{staticClass:"d-sr-only"},[_vm._v(" "+_vm._s(manager.isInvite ? "(création de compte en attente)" : "(compte créée)")+" ")])],1)],1)}),0),_c(VSpacer)],1):_c('p',{staticClass:"fr-text grey--text text--darken-3"},[_vm._v(" Actuellement, vous êtes la seule personne qui peut modifier cet établissement et ajouter des données. ")])]),_c(VSpacer),_c(VCardActions,[_c(VBtn,{staticClass:"mx-2 mb-2",attrs:{"to":{
        name: 'CanteenManagers',
        params: { canteenUrlComponent: this.$store.getters.getCanteenUrlComponent(_vm.canteen) },
      },"outlined":"","color":"primary"}},[_vm._v(" Gérer mon équipe ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }