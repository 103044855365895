import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"text-h4 font-weight-black mb-12"},[_vm._v("Nos outils et ressources")]),_c(VRow,{staticClass:"text-left"},[_c(VCol,{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('p',[_vm._v("Simplifiez les calculs de la loi EGAlim en ajoutant vos achats")]),_c(VCard,{attrs:{"elevation":"1","max-width":"400px"}},[_c(VImg,{attrs:{"src":"/static/images/features/achats.png"}})],1),_c('DownloadLink',{staticClass:"mt-4",attrs:{"href":(_vm.filepath + ".xlsx"),"label":"Ou téléchargez notre tableur","sizeStr":_vm.filesizeStr}})],1)]),_c(VCol,{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('p',[_vm._v("Positionnez-vous sur les objectifs et démarches de la loi")]),_c(VCard,{attrs:{"elevation":"1","max-width":"400px"}},[_c(VImg,{attrs:{"src":"/static/images/features/diagnostic.png"}})],1)],1)]),_c(VCol,{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('p',[_vm._v("Informez vos convives en communiquant sur vos initiatives")]),_c(VCard,{attrs:{"elevation":"1","max-width":"400px"}},[_c(VImg,{attrs:{"src":"/static/images/features/publier.png"}})],1),_c('p',{staticClass:"mt-4"},[_vm._v(" Ou utilisez notre "),_c('router-link',{attrs:{"to":{ name: 'GeneratePosterPage' }}},[_vm._v("générateur d'affiche")])],1)],1)])],1),_c(VRow,[_c(VCol,{staticClass:"d-flex",attrs:{"cols":"12"}},[_c(VSpacer),(!_vm.loggedUser)?_c(VBtn,{staticClass:"mr-2",attrs:{"href":"/s-identifier","color":"primary","large":"","outlined":""}},[_vm._v("M'identifier")]):_vm._e(),(!_vm.loggedUser)?_c(VBtn,{attrs:{"href":"/creer-mon-compte","color":"primary","large":""}},[_vm._v("Créer mon compte")]):_vm._e(),(_vm.loggedUser)?_c(VBtn,{attrs:{"to":{ name: 'ManagementPage' },"color":"primary","large":"","outlined":""}},[_vm._v(" Gérer mes cantines ")]):_vm._e(),_c(VSpacer)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }