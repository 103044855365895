import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"blog-page"}},[(_vm.blogPost)?_c('div',[_c('BreadcrumbsNav',{attrs:{"title":_vm.blogPost.title,"links":[{ to: { name: 'BlogsHome' } }]}}),_c(VCard,{staticClass:"text-center text-md-left my-10",attrs:{"elevation":"0"}},[(_vm.$vuetify.breakpoint.smAndDown)?_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VImg,{attrs:{"max-height":"100px","contain":"","src":"/static/images/lighthouse.png"}})],1)],1):_vm._e(),_c(VRow,[_c(VSpacer),(_vm.$vuetify.breakpoint.mdAndUp)?_c(VCol,{attrs:{"cols":"12","sm":"3"}},[_c(VImg,{attrs:{"max-height":"90px","contain":"","src":"/static/images/lighthouse.png"}})],1):_vm._e(),_c(VCol,{attrs:{"cols":"12","sm":"9"}},[_c(VCardTitle,[_c('h1',{staticClass:"font-weight-black text-h5 text-sm-h4 mb-2"},[_vm._v(_vm._s(_vm.blogPost.title))])]),_c(VCardSubtitle,[_c('p',{staticClass:"mb-0"},[_vm._v(" Publié le "+_vm._s(new Date(_vm.blogPost.displayDate).toLocaleDateString("fr-FR", { year: "numeric", month: "long", day: "numeric", }))+" "),(_vm.author)?_c('span',[_vm._v("par "+_vm._s(_vm.author))]):_vm._e()])])],1),_c(VSpacer)],1)],1),_c('div',{staticClass:"text-left",attrs:{"id":"content"},domProps:{"innerHTML":_vm._s(_vm.blogPost.body)}}),_c('BackLink',{staticClass:"my-10 d-block",attrs:{"to":_vm.backLink,"text":"Retour à la liste des articles","primary":true}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }