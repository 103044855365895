import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,{staticClass:"text-left d-flex flex-column dsfr expanded-link canteen-card",attrs:{"outlined":""}},[_c(VRow,{staticClass:"pa-0 ma-0"},[(_vm.showImage)?_c(VCol,{staticClass:"pa-0 card-image-wrap",attrs:{"cols":"4"}},[_c('img',{staticClass:"lead-image",attrs:{"src":_vm.canteen.leadImage ? _vm.canteen.leadImage.image : '/static/images/canteen-default-image.jpg',"alt":""}})]):_vm._e(),_c(VCol,{staticClass:"pa-8 d-flex flex-column justify-space-between"},[_c('div',[_c('h3',{staticClass:"fr-h5 mb-1"},[_c('router-link',{attrs:{"to":{
                name: 'CanteenPage',
                params: { canteenUrlComponent: _vm.$store.getters.getCanteenUrlComponent(_vm.canteen) },
              }}},[_vm._v(" "+_vm._s(_vm.canteen.name)+" ")])],1),_c('ProductionTypeTag',{attrs:{"canteen":_vm.canteen,"position":_vm.showImage ? 'top-left' : ''}}),_c('CanteenIndicators',{staticClass:"my-2",attrs:{"useCategories":true,"canteen":_vm.canteen,"singleLine":true,"dense":true}})],1),(_vm.year)?_c('p',{staticClass:"my-2 fr-text-sm"},[_vm._v(" En "+_vm._s(_vm.year)+" : "),(_vm.hasPercentages)?_c('span',{staticClass:"ma-0"},[(_vm.bioPercent)?_c('span',{staticClass:"ma-0 mr-3"},[_c('span',{staticClass:"font-weight-black mr-1"},[_vm._v(_vm._s(_vm.bioPercent)+" %")]),_vm._v(" bio ")]):_vm._e(),(_vm.sustainablePercent)?_c('span',{staticClass:"ma-0"},[_c('span',{staticClass:"font-weight-black mr-1"},[_vm._v(_vm._s(_vm.sustainablePercent)+" %")]),_vm._v(" de qualité et durables ")]):_vm._e()]):_vm._e()]):_vm._e(),(_vm.year)?_c('div',{staticClass:"d-flex my-2"},_vm._l((_vm.orderedBadges),function(badge){return _c('img',{key:badge.key,class:_vm.dense ? 'mr-2' : 'mr-4',attrs:{"src":("/static/images/badges/" + (badge.key) + (_vm.badgeIsEarned(badge) ? '' : '-disabled') + ".svg"),"width":_vm.dense ? '35px' : '50px',"height":_vm.dense ? '35px' : '50px',"alt":_vm.badgeTitle(badge),"title":_vm.badgeTitle(badge)}})}),0):_c('p',{staticClass:"my-2 fr-text-sm"},[_vm._v("Pas de données renseignées")]),_c(VCardActions,{staticClass:"px-4 py-0"},[_c(VSpacer),_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("$arrow-right-line")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }