import { VCard } from 'vuetify/lib/components/VCard';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VExpansionPanels,{attrs:{"hover":"","accordion":"","tile":"","flat":""}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"py-0",attrs:{"expand-icon":"mdi-plus","disable-icon-rotate":""}},[_c(VRow,[_c(VIcon,{staticClass:"mr-2"},[_vm._v("$question-line")]),_c('p',{staticClass:"my-3 caption"},[_vm._v(" Comment utiliser le suivi d'achats ? ")])],1)],1),_c(VExpansionPanelContent,{staticClass:"faq-answer"},[_c(VCard,{staticClass:"pa-3",class:{ 'd-flex': true, 'flex-column': _vm.$vuetify.breakpoint.xs, 'align-center': _vm.$vuetify.breakpoint.xs },attrs:{"outlined":""}},[_c('div',{style:(("max-width: " + (_vm.$vuetify.breakpoint.xs ? '70%' : '50%') + "; background: #333; border-radius: 10px; position: relative; overflow: hidden;"))},[_c('video',{ref:"video",staticStyle:{"width":"100%","background":"#333"},attrs:{"poster":"/static/images/video-poster-achats.webp","controls":""}},[_c('source',{attrs:{"type":"video/mp4","src":"https://cellar-c2.services.clever-cloud.com/ma-cantine-egalim/videos/Mes achats - ma cantine - 3 January 2023.mp4"}}),_vm._v(" Votre navigateur ne peut pas afficher des vidéos. ")])]),_c('div',[_c('p',{staticClass:"ma-4"},[_vm._v(" Régardez notre vidéo tutorial pour repondre aux questions les plus fréquentes. "),_c('br'),_c('br'),_vm._v(" Si vous avez toujours des questions ou des problèmes, n'hésitez pas à nous contacter à "),_c('a',{attrs:{"href":"mailto:support-egalim@beta.gouv.fr"}},[_vm._v("support-egalim@beta.gouv.fr")]),_vm._v(" . ")])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }