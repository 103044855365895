import { render, staticRenderFns } from "./DsfrTabs.vue?vue&type=template&id=cae1a296&scoped=true&"
import script from "./DsfrTabs.vue?vue&type=script&lang=js&"
export * from "./DsfrTabs.vue?vue&type=script&lang=js&"
import style0 from "./DsfrTabs.vue?vue&type=style&index=0&id=cae1a296&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cae1a296",
  null
  
)

export default component.exports