import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,[_c(VCol,{staticClass:"pa-4 pa-md-0",attrs:{"cols":"12","sm":"8","md":"7"}},[_c(VCard,{staticClass:"pa-0",attrs:{"elevation":"0"}},[_c(VCardTitle,[_c('h2',{staticClass:"fr-h5 mb-2"},[_vm._v(" Suivre les actualités du site ma cantine ")])]),_c(VCardSubtitle,{staticClass:"text-left"},[_c('p',{staticClass:"mb-0"},[_vm._v(" Inscrivez-vous à la newsletter et recevez environ 1 email par mois. ")])]),_c(VCardText,[_c(VForm,{ref:"form",staticClass:"d-sm-flex align-sm-center text-left",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.formIsValid),callback:function ($$v) {_vm.formIsValid=$$v},expression:"formIsValid"}},[_c('DsfrEmail',{staticClass:"flex-grow-1 mb-sm-6",model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c(VBtn,{staticClass:"ml-sm-4",attrs:{"outlined":"","color":"primary","large":""},on:{"click":_vm.subscribe}},[_vm._v("Valider")])],1)],1)],1)],1),(_vm.$vuetify.breakpoint.smAndUp)?_c(VCol,{staticClass:"d-flex align-center",attrs:{"cols":"4","md":"2"}},[_c(VImg,{attrs:{"src":"/static/images/doodles-dsfr/primary/LayingDoodle.png","contain":"","max-height":"100px"}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }