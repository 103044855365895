import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"500"},model:{value:(_vm.dialogOpen),callback:function ($$v) {_vm.dialogOpen=$$v},expression:"dialogOpen"}},[_c(VCard,{staticClass:"text-left pa-1"},[_c(VCardTitle,[_c('h1',{staticClass:"fr-h5 mb-2"},[_vm._v("Vérification")])]),_c(VCardText,{staticClass:"grey--text text--darken-3"},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.bodyText))])]),_c(VDivider,{attrs:{"aria-hidden":"true","role":"presentation"}}),_c(VCardActions,{staticClass:"pa-3"},[_c(VSpacer),_c(VBtn,{staticClass:"mr-2",attrs:{"outlined":"","text":""},on:{"click":_vm.onCancel}},[_vm._v(" Non, revenir en arrière ")]),_c(VBtn,{attrs:{"outlined":"","color":"primary","text":""},on:{"click":_vm.onConfirm}},[_vm._v(" Oui, sauvegarder ma cantine ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }