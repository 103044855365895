import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"dsfr",attrs:{"outlined":"","to":{
    name: 'VideoTutorial',
    params: { webinaireUrlComponent: ((_vm.videoTutorial.id) + "--" + (_vm.videoTutorial.title)) },
  }}},[_c(VCardTitle,{staticClass:"d-block"},[_c('h4',{staticClass:"text-body-1 font-weight-bold"},[_c(VIcon,{staticClass:"mr-1 mt-n1",attrs:{"color":"grey darken-3"}},[_vm._v("$film-fill")]),_vm._v(" "+_vm._s(_vm.videoTutorial.title)+" ")],1)]),(_vm.videoTutorial.description)?_c(VCardText,[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.videoTutorial.description))])]):_vm._e(),_c(VSpacer),_c(VCardActions,{staticClass:"px-4 pt-0"},[_c(VSpacer),_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("$arrow-right-line")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }