import { VCol } from 'vuetify/lib/components/VGrid';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRadioGroup,_vm._g(_vm._b({ref:"radiogroup",staticClass:"my-0",on:{"change":_vm.change},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"d-block"},[_c('span',{class:_vm.legendClass},[_vm._v(" "+_vm._s(_vm.$attrs.label)+" ")]),(_vm.optional)?_c('span',{staticClass:"fr-hint-text mb-2"},[_vm._v("Optionnel")]):_vm._e()])]},proxy:true},{key:"message",fn:function(ref){
var key = ref.key;
var message = ref.message;
return [_c('p',{key:key,staticClass:"mb-0",attrs:{"id":_vm.errorMessageId}},[_vm._v(_vm._s(message))])]}}])},'v-radio-group',_vm.$attrs,false),_vm.$listeners),[(_vm.optionsRow)?_c(VRow,{staticClass:"my-0"},_vm._l((_vm.items),function(item){return _c(VCol,{key:item.value,staticClass:"py-2",attrs:{"cols":"12","sm":"6"}},[_c(VRadio,{class:_vm.optionClasses,attrs:{"value":item.value},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{class:_vm.optionClasses},[_vm._v(" "+_vm._s(item.label || item.text)+" ")])]},proxy:true}],null,true)})],1)}),1):_vm._l((_vm.items),function(item){return _c(VRadio,{key:item.value,class:_vm.optionClasses,attrs:{"value":item.value},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{class:_vm.optionClasses},[_vm._v(" "+_vm._s(item.label || item.text)+" ")])]},proxy:true}],null,true)})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }