import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"1000"},on:{"click:outside":function($event){return _vm.$emit('input', false)}},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c(VSheet,{staticClass:"pa-8 text-left"},[_c('h1',{staticClass:"fr-h3"},[_vm._v(" Créez votre cantine ")]),_c('p',[_vm._v(" Cliquez sur l'établissement qui correspond à votre cantine ")]),_c('div',{staticClass:"mt-4"},[_vm._l((_vm.organizations),function(organization){return _c(VCard,{key:organization.id,staticClass:"text-left mb-4",attrs:{"outlined":"","to":{ name: 'NewCanteen', query: { siret: organization.siret } }}},[_c(VCardTitle,[_c('h2',{staticClass:"fr-h6 mb-0"},[_vm._v(_vm._s(organization.label))])]),_c(VCardText,[_c('p',{staticClass:"mb-0"},[_vm._v("SIRET : "+_vm._s(organization.siret))])])],1)}),_c(VDivider,{staticClass:"mb-4",attrs:{"aria-hidden":"true","role":"presentation"}}),_c(VCard,{staticClass:"text-left mb-4",attrs:{"outlined":"","to":{ name: 'NewCanteen' }}},[_c(VCardTitle,[_c('h2',{staticClass:"fr-h6 mb-0"},[_vm._v("Créer un autre établissement")])])],1)],2),_c('div',{staticClass:"d-flex"},[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v("Fermer")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }