import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,_vm._l((_vm.resources),function(resource){return _c(VCol,{key:resource.title,attrs:{"cols":"12","sm":"4"}},[_c(VCard,{attrs:{"elevation":"0"}},[_c(VCardTitle,[_c('h2',{staticClass:"text-body-1 text-left font-weight-bold d-flex align-center"},[_c(VIcon,{attrs:{"color":"grey darken-3 mr-2"}},[_vm._v(_vm._s(resource.icon))]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(resource.title))])],1)]),_c(VCardText,{staticClass:"text-left"},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(resource.description)+" ")])]),_c(VCardActions,{staticClass:"px-4"},[_c(VBtn,{attrs:{"outlined":"","color":"primary","to":resource.to,"href":resource.url,"target":resource.url ? '_blank' : null,"rel":resource.url ? 'noopener noreferrer' : null,"title":resource.url ? ((resource.ctaText) + " - ouvre une nouvelle fenêtre") : null}},[_vm._v(" "+_vm._s(resource.ctaText)+" "),(resource.url)?_c(VIcon,{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("mdi-open-in-new")]):_vm._e()],1)],1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }