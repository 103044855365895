import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VExpansionPanels,{staticClass:"dsfr-accordion",attrs:{"hover":"","accordion":"","tile":"","flat":""},model:{value:(_vm.openPanel),callback:function ($$v) {_vm.openPanel=$$v},expression:"openPanel"}},_vm._l((_vm.items),function(item){return _c(VExpansionPanel,{key:item.title},[_c(VExpansionPanelHeader,{staticClass:"px-3 fr-accordion__btn",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c(item.titleLevel || 'h3',{tag:"component",staticClass:"fr-text",class:open && 'active-panel'},[(item.title)?_c('span',[_vm._v(_vm._s(item.title))]):_vm._t("title",null,{"item":item})],2)]}}],null,true)}),_c(VExpansionPanelContent,{staticClass:"px-3 pt-4 pb-8",attrs:{"eager":""}},[(item.content)?_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.content))]):_vm._t("content",null,{"item":item})],2)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }