import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"pa-4 dsfr",attrs:{"href":_vm.webinaire.link,"outlined":""}},[_c(VRow,[(_vm.$vuetify.breakpoint.smAndUp)?_c(VCol,{staticClass:"justify-center align-center d-flex",attrs:{"cols":"1"}},[_c(VAvatar,{attrs:{"color":"secondary lighten-1","size":"40"}},[_c(VIcon,[_vm._v(_vm._s(_vm.icon))])],1)],1):_vm._e(),_c(VCol,{staticClass:"align-center d-flex",attrs:{"cols":"12","sm":"3"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.webinaire.title))])]),_c(VCol,{staticClass:"align-center d-flex body-2",attrs:{"cols":"12","sm":"5"}},[_vm._v(" "+_vm._s(_vm.webinaire.tagline)+" ")]),_c(VCol,{staticClass:"align-center d-flex",attrs:{"cols":"12","sm":"3"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"font-weight-bold body-1"},[_vm._v(_vm._s(_vm.date))]),_c('div',{staticClass:"body-2 my-2"},[_c(VIcon,{attrs:{"small":"","color":"primary"}},[_vm._v("mdi-map-marker")]),_vm._v(" "+_vm._s(_vm.webinaire.address || "Visio - conférence")+" ")],1),_c(VBtn,{attrs:{"color":"primary","width":"120"}},[_vm._v(" Je m'inscris "),_c('span',{staticClass:"d-sr-only"},[_vm._v("à "+_vm._s(_vm.webinaire.title))])])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }