import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-n2"},[_c(VRow,{staticClass:"mt-2"},[_c(VCol,{attrs:{"cols":"12","sm":"4","md":"3"}},[_c(VCard,{staticClass:"mt-4",attrs:{"outlined":""}},[_c(VList,{staticClass:"text-left",attrs:{"nav":""}},[_c(VListItemGroup,[_c(VListItem,{attrs:{"ripple":false,"to":{ name: 'AccountEditor' }}},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("$account-circle-fill")]),_c(VListItemTitle,{staticClass:"text-body-2 font-weight-bold"},[_vm._v("Profil")])],1),_c(VListItem,{staticClass:"rounded-lg",attrs:{"ripple":false,"to":{ name: 'PasswordChange' }}},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-key")]),_c(VListItemTitle,{staticClass:"text-body-2 font-weight-bold"},[_vm._v("Mot de passe")])],1),_c(VListItem,{staticClass:"rounded-lg",attrs:{"ripple":false,"to":{ name: 'AccountDeletion' }}},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("$delete-fill")]),_c(VListItemTitle,{staticClass:"text-body-2 font-weight-bold"},[_vm._v("Supprimer mon compte")])],1)],1)],1)],1)],1),_c(VCol,[_c('router-view',{key:_vm.$route.fullPath})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }