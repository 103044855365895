<template>
  <div class="fr-text">
    <p>
      Tous les restaurants collectifs scolaires (publics ou privés), de la maternelle au lycée, doivent proposer, au
      moins une fois par semaine, un menu végétarien. Par ailleurs, le menu végétarien doit s'insérer dans un plan
      alimentaire respectueux des exigences relatives à la qualité nutritionnelle.
    </p>
    <p>
      Tous les gestionnaires de cantine servant plus de 200 couverts par jour doivent élaborer un plan pluriannuel de
      diversification de protéines incluant des alternatives à base de protéines végétales dans les repas.
    </p>
    <p>
      Depuis 2023, les restaurants collectifs de l'État, les établissements publics et les entreprises publiques
      nationales doivent proposer une option végétarienne quotidienne, dès lors qu'ils proposent habituellement un choix
      multiple de menus.
    </p>
    <p>
      Déclarez vos actions entreprises sur ce thème pour évaluer votre situation et bénéficiez de ressources
      personnalisées : conseils pratiques, recettes, fournisseurs agréés de votre région, autres établissements dont
      s’inspirer....
    </p>
  </div>
</template>

<script>
export default {
  name: "DiversificationMeasureInfo",
}
</script>
