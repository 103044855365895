import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"red","x-small":"","fab":"","outlined":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"aria-hidden":"false","title":"Enlever"}},[_vm._v("$delete-fill")])],1)]}}]),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c(VCard,{staticClass:"text-left"},[_c(VCardTitle,[_c('h1',{staticClass:"fr-h5 mb-2"},[_vm._v("Voulez-vous enlever "+_vm._s(_vm.manager.email)+" de la liste de gestionnaires ?")])]),_c(VCardText,[_c('p',{staticClass:"mb-0"},[(_vm.manager.firstName || _vm.manager.lastName)?_c('span',[_vm._v(_vm._s(_vm.manager.firstName)+" "+_vm._s(_vm.manager.lastName))]):_c('span',[_vm._v("Cette personne")]),_vm._v(" n'aura plus accès à cette cantine et ne sera plus en mesure d'en apporter des modifications ni de créer des diagnostics. ")])]),_c(VDivider,{attrs:{"aria-hidden":"true","role":"presentation"}}),_c(VCardActions,{staticClass:"pa-4"},[_c(VSpacer),_c(VBtn,{staticClass:"mr-2",attrs:{"outlined":"","text":""},on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v(" Non, revenir en arrière ")]),_c(VBtn,{attrs:{"outlined":"","color":"red darken-2","text":""},on:{"click":function($event){return _vm.$emit('delete')}}},[_vm._v(" Oui, enlever "+_vm._s(_vm.manager.firstName)+" "+_vm._s(_vm.manager.lastName)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }