import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"fill-height text-left d-flex flex-column dsfr",attrs:{"outlined":"","to":{ name: 'BlogPage', params: { id: _vm.post.id } }}},[_c(VCardTitle,{staticClass:"d-flex flex-column-reverse align-start"},[_c(_vm.hLevel,{tag:"component",staticClass:"text-h6 font-weight-bold"},[_vm._v(_vm._s(_vm.post.title))]),(_vm.tags.length)?_c('DsfrTagGroup',{attrs:{"tags":_vm.tags,"closeable":false,"small":true}}):_vm._e()],1),_c(VCardSubtitle,{staticClass:"pt-1"},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(new Date(_vm.post.displayDate).toLocaleDateString("fr-FR", { year: "numeric", month: "long", day: "numeric", }))+" ")])]),_c(VCardText,[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.post.tagline))])]),_c(VSpacer),_c(VCardActions,{staticClass:"px-4 py-4"},[_c(VSpacer),_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("$arrow-right-line")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }