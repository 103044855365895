import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"8"}},[_c('h2',{staticClass:"font-weight-black text-h5 mb-4"},[_vm._v("Rejoindre la communauté")]),_c('p',[_vm._v(" Vous êtes gestionnaire en restauration collective et souhaitez vous appuyer sur une communauté de paires pour élargir votre réseau, communiquer sur vos bonnes pratiques, partager des opportunités ou demander des conseils ? Rejoignez le groupe Facebook de rencontre, d'entraide et d'échange "),_c('strong',[_vm._v("« Ma Cantine - groupe d’entraide gestionnaires »")])]),_c(VBtn,{attrs:{"outlined":"","color":"primary","large":"","href":"https://www.facebook.com/groups/719336016153227/","target":"_blank","rel":"noopener"}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-facebook")]),_vm._v(" Rejoindre le groupe Facebook ")],1)],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c(VCol,{attrs:{"cols":"4"}},[_c(VCard,{attrs:{"href":"https://www.facebook.com/groups/719336016153227/","target":"_blank","rel":"noopener"}},[_c(VImg,{attrs:{"contain":"","src":"/static/images/facebook-cover.jpg","alt":"ma-cantine.beta.gouv.fr (accéder au groupe facebook) - communauté d'entraide et d'échange de la restauration collective"}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }