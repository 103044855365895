import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,[_c(VCol,[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.formIsValid),callback:function ($$v) {_vm.formIsValid=$$v},expression:"formIsValid"}},[_c('DsfrEmail',{model:{value:(_vm.fromEmail),callback:function ($$v) {_vm.fromEmail=$$v},expression:"fromEmail"}}),_c('DsfrFullName',{model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('DsfrNativeSelect',{staticClass:"mb-8",attrs:{"items":_vm.inquiryOptions,"label":"Type de demande","rules":[_vm.validators.required]},model:{value:(_vm.inquiryType),callback:function ($$v) {_vm.inquiryType=$$v},expression:"inquiryType"}}),_c('DsfrTextarea',{staticClass:"mt-2",attrs:{"label":"Message","rules":[_vm.validators.required]},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('p',{staticClass:"caption grey--text text--darken-1 mt-n1 mb-6"},[_vm._v(" Ne partagez pas d'informations sensibles (par ex. mot de passe, numéro de carte bleue, etc). ")])],1),_c(VBtn,{staticClass:"mt-0 mb-6",attrs:{"x-large":"","color":"primary"},on:{"click":_vm.sendEmail}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-send")]),_vm._v(" Envoyer ")],1),_c('p',{staticClass:"grey--text text--darken-1"},[_vm._v(" Si vous n'arrivez pas à utiliser le formulaire ci-dessus, vous pouvez nous contacter directement par email à l'adresse suivante: "),_c('a',{attrs:{"href":"mailto:support-egalim@beta.gouv.fr"}},[_vm._v("support-egalim@beta.gouv.fr")])])],1),(_vm.$vuetify.breakpoint.smAndUp)?_c(VCol,{attrs:{"cols":"4"}},[_c('div',{staticClass:"fill-height d-flex flex-column align-center"},[_c(VSpacer),_c(VImg,{staticStyle:{"transform":"scaleX(-1)"},attrs:{"src":"/static/images/doodles-dsfr/primary/SittingDoodle.png","contain":""}}),_c(VSpacer)],1)]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }