import { render, staticRenderFns } from "./InformationMeasureSummary.vue?vue&type=template&id=961982c2&scoped=true&"
import script from "./InformationMeasureSummary.vue?vue&type=script&lang=js&"
export * from "./InformationMeasureSummary.vue?vue&type=script&lang=js&"
import style0 from "./InformationMeasureSummary.vue?vue&type=style&index=0&id=961982c2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "961982c2",
  null
  
)

export default component.exports