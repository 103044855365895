import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,{staticClass:"text-center text-md-right",attrs:{"elevation":"0"}},[(_vm.$vuetify.breakpoint.smAndDown)?_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VImg,{attrs:{"max-height":"150px","contain":"","src":"/static/images/doodles-dsfr/primary/SittingChef.png"}})],1)],1):_vm._e(),_c(VRow,[_c(VSpacer),_c(VCol,{attrs:{"cols":"12","md":"7"}},[_c(VCardTitle,[_c(VSpacer),_c('h1',{staticClass:"font-weight-black text-h3 mt-0 mt-sm-4"},[_vm._v(" Mieux manger "),(_vm.$vuetify.breakpoint.mdAndUp)?_c('br'):_vm._e(),_vm._v(" de la crèche à l’EHPAD ")]),(_vm.$vuetify.breakpoint.smAndDown)?_c(VSpacer):_vm._e()],1)],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c(VCol,{staticClass:"d-flex flex-column",attrs:{"cols":"3"}},[_c(VSpacer),_c(VImg,{attrs:{"max-height":"200px","contain":"","src":"/static/images/doodles-dsfr/primary/SittingChef.png"}}),_c(VSpacer)],1):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c(VSpacer):_vm._e(),_c(VSpacer)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }