import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"fill-height d-flex flex-column dsfr pa-6",staticStyle:{"background-color":"#F6F6F6"},attrs:{"to":_vm.link,"outlined":""}},[_c(VCardTitle,{staticClass:"mx-1"},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":"","color":_vm.keyMeasure.mdiIconColor}},[_vm._v(" "+_vm._s(_vm.keyMeasure.mdiIcon)+" ")]),_c('h3',{staticClass:"fr-text font-weight-bold"},[_vm._v(_vm._s(_vm.keyMeasure.shortTitle))])],1),(_vm.needsData)?_c(VCardText,[_c('DataInfoBadge',{staticClass:"py-0 ml-8",attrs:{"missingData":true}})],1):(_vm.level)?_c(VCardText,{class:("mt-n4 pl-12 " + (_vm.level.colorClass))},[_c('p',{staticClass:"mb-0 mt-2 fr-text-xs"},[_vm._v(" NIVEAU : "),_c('span',{staticClass:"font-weight-black"},[_vm._v(_vm._s(_vm.level.text))])])]):_vm._e(),_c(VCardText,{staticClass:"fr-text-xs"},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.cardBody))])]),_c(VSpacer),(!_vm.delegatedToSatellite)?_c(VCardActions,{staticClass:"px-4 pt-0 mt-n2"},[_c(VSpacer),_c(VIcon,{staticClass:"mr-n1",attrs:{"color":"primary"}},[_vm._v("$arrow-right-line")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }