import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VExpansionPanels,{staticClass:"dsfr-transcription",attrs:{"hover":"","accordion":"","tile":"","flat":""}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"px-3 fr-accordion__btn",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c(_vm.titleLevel,{tag:"component",staticClass:"fr-text d-flex align-center",class:open && 'active-panel'},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":"","color":"primary"}},[_vm._v("$menu-2-fill")]),_vm._v(" Transcription ")],1)]}}])}),_c(VExpansionPanelContent,[_c('div',{staticClass:"px-4 pt-4 pb-4"},[_vm._t("default")],2),_c('div',{staticClass:"transcription-footer pa-2"},[_c(VDialog,{attrs:{"fullscreen":_vm.$vuetify.breakpoint.smAndDown,"width":_vm.$vuetify.breakpoint.mdAndUp ? 900 : undefined},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"primary--text",attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" Agrandir "),_c(VIcon,{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("mdi-arrow-expand-all")])],1)]}}]),model:{value:(_vm.transcriptionModal),callback:function ($$v) {_vm.transcriptionModal=$$v},expression:"transcriptionModal"}},[_c(VCard,{staticClass:"px-sm-4 pt-6 pb-8 text-left"},[_c(VRow,{staticClass:"justify-end ma-0"},[_c(VBtn,{staticClass:"primary--text",attrs:{"text":""},on:{"click":function($event){_vm.transcriptionModal = false}}},[_vm._v(" Fermer "),_c(VIcon,{staticClass:"ml-2",attrs:{"small":"","color":"primary"}},[_vm._v("$close-line")])],1)],1),_c(VCardTitle,[_c('h1',{staticClass:"fr-h2 mb-4"},[_vm._v("Transcription")])]),_c(VCardText,[_vm._t("default")],2)],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }