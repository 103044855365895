import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"text-left",attrs:{"elevation":"0"}},[_c(VCardTitle,[_c('h1',{staticClass:"font-weight-black text-h4 mb-4 mt-1"},[_vm._v("Changer mon mot de passe")])]),_c(VCardText,[_c(VForm,{ref:"form",model:{value:(_vm.formIsValid),callback:function ($$v) {_vm.formIsValid=$$v},expression:"formIsValid"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('DsfrTextField',{attrs:{"label":"Mot de passe actuel","type":"password","rules":[_vm.validators.required],"hide-details":"auto"},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('DsfrTextField',{attrs:{"label":"Nouveau mot de passe","type":"password","rules":[_vm.validators.required],"hide-details":"auto"},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('DsfrTextField',{attrs:{"label":"Confirmation de nouveau mot de passe","type":"password","hide-details":"auto","rules":[_vm.validators.matchPassword, _vm.validators.required],"validate-on-blur":""},model:{value:(_vm.newPasswordConfirmation),callback:function ($$v) {_vm.newPasswordConfirmation=$$v},expression:"newPasswordConfirmation"}})],1)],1)],1)],1),_c(VCardActions,{staticClass:"pa-4"},[_c(VSpacer),_c(VBtn,{attrs:{"x-large":"","color":"primary"},on:{"click":_vm.changePassword}},[_vm._v(" Valider ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }