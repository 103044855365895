import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'grey--text text--darken-2': true,
    'd-flex flex-wrap': _vm.singleLine,
    'fr-text': !_vm.dense,
    'fr-text-xs': _vm.dense,
  }},[(_vm.canteen.city)?_c('p',{class:{ 'my-0 d-flex align-center': true, 'inline mr-3': _vm.singleLine }},[_c(VIcon,{staticClass:"mr-1",attrs:{"small":!_vm.dense,"x-small":_vm.dense}},[_vm._v("$map-pin-2-line")]),_vm._v(" "+_vm._s(_vm.canteen.city)+" ")],1):_vm._e(),(_vm.hasDailyMealCount)?_c('p',{class:{ 'my-0 d-flex align-center': true, 'inline mr-3': _vm.singleLine }},[_c(VIcon,{staticClass:"mr-1",attrs:{"small":!_vm.dense,"x-small":_vm.dense,"aria-hidden":"false","role":"img"}},[_vm._v("$team-line")]),_vm._v(" "+_vm._s(_vm.canteen.dailyMealCount)+" couverts ")],1):_vm._e(),(_vm.satelliteCount)?_c('p',{class:{ 'my-0 d-flex align-center': true, 'inline mr-3': _vm.singleLine }},[_c(VIcon,{staticClass:"mr-1",attrs:{"small":!_vm.dense,"x-small":_vm.dense}},[_vm._v("$restaurant-line")]),_vm._v(" "+_vm._s(_vm.satelliteCount)+" "+_vm._s(_vm.satelliteCount === 1 ? "satellite" : "satellites")+" ")],1):_vm._e(),(_vm.businessSegments)?_c('p',{class:{ 'my-0 d-flex align-center': true, 'inline mr-3': _vm.singleLine }},[_c(VIcon,{staticClass:"mr-1",attrs:{"small":!_vm.dense,"x-small":_vm.dense}},[_vm._v("$building-line")]),_vm._v(" "+_vm._s(_vm.businessSegments)+" ")],1):_vm._e(),(_vm.managementType)?_c('p',{class:{ 'my-0 d-flex align-center': true, 'inline mr-3': _vm.singleLine }},[_c(VIcon,{staticClass:"mr-1",attrs:{"small":!_vm.dense,"x-small":_vm.dense}},[_vm._v("$group-line")]),_vm._v(" Gestion "+_vm._s(_vm.managementType.toLowerCase())+" ")],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }