import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"my-6",attrs:{"id":"#contact"}},[_vm._v("Vous avez besoin d'aide ?")]),_vm._m(0),_c(VForm,{ref:"helpForm",staticClass:"my-12",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.helpFormIsValid),callback:function ($$v) {_vm.helpFormIsValid=$$v},expression:"helpFormIsValid"}},[_c(VRow,{staticClass:"mb-1 align-end"},[_c(VCol,{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('DsfrEmail',{model:{value:(_vm.fromEmail),callback:function ($$v) {_vm.fromEmail=$$v},expression:"fromEmail"}})],1),_c(VCol,{staticClass:"py-0"},[_c('DsfrFullName',{model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)],1),_c('DsfrTextarea',{attrs:{"label":"Message"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c(VFileInput,{attrs:{"label":"Fichier","outlined":"","rules":[_vm.validators.required, _vm.validators.maxFileSize(10485760, '10 Mo')],"validate-on-blur":"","show-size":""},model:{value:(_vm.unusualFile),callback:function ($$v) {_vm.unusualFile=$$v},expression:"unusualFile"}}),_c(VBtn,{attrs:{"x-large":"","color":"primary"},on:{"click":_vm.emailUnusualFile}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-send")]),_vm._v(" Envoyer ")],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" Si votre fichier comptable agrégé ne ressemble pas du tout à ça, vous pouvez nous l'envoyer en remplissant les champs ci-dessous ou nous contacter directement à l'adresse "),_c('a',{attrs:{"href":"mailto:support-egalim@beta.gouv.fr"}},[_vm._v("support-egalim@beta.gouv.fr")]),_vm._v(" . ")])}]

export { render, staticRenderFns }