import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"primary--text",attrs:{"text":"","elevation":"0"}},'v-btn',attrs,false),on),[_c('span',[_vm._v("Me déconnecter")])])]}}]),model:{value:(_vm.logoutWarningDialog),callback:function ($$v) {_vm.logoutWarningDialog=$$v},expression:"logoutWarningDialog"}},[_c(VCard,[_c(VCardText,{staticClass:"pa-8 text-left"},[_c('p',{staticClass:"mb-0"},[_vm._v("Voulez-vous vous déconnecter de votre compte ma cantine ?")])]),_c(VDivider,{attrs:{"aria-hidden":"true","role":"presentation"}}),_c(VCardActions,{staticClass:"flex-wrap align-end"},[_c(VBtn,{staticClass:"mr-1",attrs:{"color":"grey darken-2","text":""},on:{"click":function($event){_vm.logoutWarningDialog = false}}},[_vm._v(" Non, revenir en arrière ")]),_c(VBtn,{attrs:{"color":"red darken-2","text":""},on:{"click":_vm.logout}},[_vm._v(" Oui, je confirme ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }