import { render, staticRenderFns } from "./KeyMeasureDescription.vue?vue&type=template&id=40a63e76&scoped=true&"
import script from "./KeyMeasureDescription.vue?vue&type=script&lang=js&"
export * from "./KeyMeasureDescription.vue?vue&type=script&lang=js&"
import style0 from "./KeyMeasureDescription.vue?vue&type=style&index=0&id=40a63e76&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40a63e76",
  null
  
)

export default component.exports