import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import ClickOutside from 'vuetify/lib/directives/click-outside';
import Resize from 'vuetify/lib/directives/resize';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.visible)?_c('div',{directives:[{def: Resize,name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}]},[_c(VOverlay,{attrs:{"value":_vm.visible,"dark":false}},[_c(VBtn,{staticClass:"close-overlay",attrs:{"fab":"","dark":"","small":"","color":"grey lighten-5"},on:{"click":_vm.close}},[_c(VIcon,{attrs:{"color":"red darken-3","aria-label":"Fermer","aria-hidden":"false"}},[_vm._v("$close-line")])],1),_c(VCarousel,{directives:[{def: ClickOutside,name:"click-outside",rawName:"v-click-outside",value:(_vm.close),expression:"close"}],attrs:{"value":_vm.index,"height":_vm.carouselHeight,"hide-delimiter-background":"","hide-delimiters":_vm.items.length <= 1,"show-arrows":_vm.items.length > 1}},_vm._l((_vm.items),function(item,idx){return _c(VCarouselItem,{key:idx,staticStyle:{"background":"#333","border-radius":"5px","overflow":"hidden"},attrs:{"width":_vm.carouselWidth,"src":item.image,"alt":item.altText,"contain":""}})}),1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }