<template>
  <div class="fr-text">
    <p>
      Votre établissement est soumis à l’obligation de faire une analyse des causes du gaspillage alimentaire, et de
      mettre en place une démarche de lutte contre le gaspillage alimentaire.
    </p>
    <p>
      Évaluez votre situation en déclarant les actions déjà entreprises, et bénéficiez ainsi de ressources
      personnalisées selon votre situation pour aller plus loin.
    </p>
  </div>
</template>

<script>
export default {
  name: "WasteMeasureInfo",
}
</script>
